import { useEffect, useState } from 'react'
import { formatPrice } from '../../../utils'
import Backdrop from '../tools/Backdrop'
import Input from './Input'
import ArrowDropDownIcon from '../icons/arrows/ArrowDropDownIcon'
import ArrowDropUpIcon from '../icons/arrows/ArrowDropUpIcon'
import currencyList from './currencyList'
import './forms.css'

const CurrencyConverterForm = ({ value }) => {

    const currenciesList = Object.values( currencyList )

    const [ currencyOne,    setCurrencyOne    ] = useState('THB')
    const [ currencyTwo,    setCurrencyTwo    ] = useState('USD')
    const [ countryOneCode, setCountryOneCode ] = useState('th')
    const [ countryTwoCode, setCountryTwoCode ] = useState('us')
    const [ input,          setInput          ] = useState(value ? value : 1)
    const [ rate,           setRate           ] = useState(0)
    const [ isOneOpen,      setIsOneOpen      ] = useState(false)  
    const [ isTwoOpen,      setIsTwoOpen      ] = useState(false)  
    const [ searchOneValue, setSearchOneValue ] = useState('') 
    const [ searchTwoValue, setSearchTwoValue ] = useState('')     

    const handleSelectOneChange = (e) => {
        setCurrencyOne(e.target[0].value)  
        setCountryOneCode(e.target[1].value)
        setIsOneOpen(false)             
    }

    const handleSelectTwoChange = (e) => {
        setCurrencyTwo(e.target[0].value)  
        setCountryTwoCode(e.target[1].value) 
        setIsTwoOpen(false)             
    }

    const toggleDropdownOne = () => {
        setIsOneOpen(!isOneOpen)
        setSearchOneValue('')
    }  

    const toggleDropdownTwo = () => {
        setIsTwoOpen(!isTwoOpen)
        setSearchTwoValue('')
    }

    useEffect(() => {
        fetch(`https:///api.exchangerate-api.com/v4/latest/${currencyOne}`)
            .then(res => res.json())
            .then(data=> setRate(data.rates[currencyTwo]))
    }, [currencyOne, currencyTwo])

    const handleSwap = () => {
        setCurrencyOne(currencyTwo)
        setCurrencyTwo(currencyOne)
    }

    const handleInputOneChange = (e) => {
        setSearchOneValue(e.target.value)
    } 

    const handleInputTwoChange = (e) => {
        setSearchTwoValue(e.target.value)
    }

    const filteredDataOne = currenciesList.filter((item) =>
        item.value && item.value.toLowerCase().includes(searchOneValue.toLowerCase())
    ) 

    const filteredDataTwo = currenciesList.filter((item) =>
        item.value && item.value.toLowerCase().includes(searchTwoValue.toLowerCase())
    ) 

    return (
        <>
        {isOneOpen && (
            <Backdrop
                invisible
                onClick={toggleDropdownOne}
            /> 
        )}

        <div className="select-container">
            <div 
                className={`label ${isOneOpen ? 'open' : ''}`} 
                onClick={toggleDropdownOne}
            >
                <div tabIndex={0} className="input focusable-div">
                    <img
                        alt={currencyOne} 
                        src={`https://flagcdn.com/${countryOneCode}.svg`}
                        style={{ width: '20px', marginRight: '10px' }}
                    />
                    {currencyOne}
                </div>

                <small>Currency one</small>
                
                <span className="adornment-end">
                   
                    {isOneOpen 
                        ? <ArrowDropUpIcon /> 
                        : <ArrowDropDownIcon />                            
                    }

                </span>

            </div>

            {isOneOpen && (                
                <>
                <ul className="select-dropdown modal-input fullHeight">
                    <li>
                        <input
                            className="input"
                            placeholder="Search..."
                            value={searchOneValue}
                            onChange={handleInputOneChange}
                        />
                    </li>
                    {filteredDataOne.map(c => (  
                        <li 
                            key={c.value} 
                            onClick={() => handleSelectOneChange({ target: [{ value: c.value }, { value: c.code.toLowerCase() }] })}
                        >
                            <img
                                alt={c.value} 
                                src={`https://flagcdn.com/${c.code.toLowerCase()}.svg`}
                                style={{ width: '20px', marginRight: '10px' }}
                            />                                    
                            {c.value} 
                        </li>                                  
                    ))}  
                </ul>
                </>
            )}

            <Input                     
                value={input}
                type="number"
                onChange={(e) => setInput(e.target.value)}  
                placeholder="Result" 
            /> 

        </div>

        <button 
            className="button" 
            style={{ margin: '40px 0' }}
            onClick={handleSwap}
        >
            Swap
        </button>

        <div className="text-center" style={{ marginBottom: '40px' }}>
            1 {currencyOne}
            &nbsp;  
            <img
                alt={currencyOne} 
                src={`https://flagcdn.com/${countryOneCode}.svg`}
                style={{ width: '20px', marginRight: '10px' }}
            />
            &nbsp;= &nbsp; 
            {rate}             
            &nbsp;
            {currencyTwo}
            &nbsp; 
            <img
                alt={currencyTwo} 
                src={`https://flagcdn.com/${countryTwoCode}.svg`}
                style={{ width: '20px', marginRight: '10px' }}
            />
        </div>  

        {isTwoOpen && (
            <Backdrop
                invisible
                onClick={toggleDropdownTwo}
            /> 
        )}

        <div className="select-container">
            <div 
                className={`label ${isTwoOpen ? 'open' : ''}`} 
                onClick={toggleDropdownTwo}
            >
                <div tabIndex={0} className="input focusable-div">
                    <img
                        alt={currencyTwo} 
                        src={`https://flagcdn.com/${countryTwoCode}.svg`}
                        style={{ width: '20px', marginRight: '10px' }}
                    />
                    {currencyTwo}
                </div>                

                <small>Currency Two</small>
                
                <span className="adornment-end">
                   
                    {isTwoOpen 
                        ? <ArrowDropUpIcon /> 
                        : <ArrowDropDownIcon />                            
                    }

                </span>

            </div>

            {isTwoOpen && (                
                <>
                <ul className="select-dropdown modal-input fullHeight">
                    <li>
                        <input
                            className="input"
                            placeholder="Search..."
                            value={searchTwoValue}
                            onChange={handleInputTwoChange}
                        />
                    </li>
                    {filteredDataTwo.map(c => (  
                        <li 
                            key={c.value} 
                            onClick={() => handleSelectTwoChange({ target: [{ value: c.value }, { value: c.code.toLowerCase() }] })}
                        >
                            <img
                                alt={c.value} 
                                src={`https://flagcdn.com/${c.code.toLowerCase()}.svg`}
                                style={{ width: '20px', marginRight: '10px' }}
                            />                                    
                            {c.value} 
                        </li>                                  
                    ))}  
                </ul>
                </>
            )}

            <div className="text-center" style={{ marginTop: 40, fontSize: '24px' }}>           

                <strong>{formatPrice((input * rate).toFixed(2), currencyTwo)}</strong>    
                &nbsp;
                <img
                    alt={currencyTwo} 
                    src={`https://flagcdn.com/${countryTwoCode}.svg`}
                    style={{ width: '36px', marginRight: '18px' }}
                />  

            </div> 

        </div>

         
        </>
    )

}

export default CurrencyConverterForm