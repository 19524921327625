import { useEffect, useState } from 'react'
import LinearProgressBar from './linearProgressBar/LinearProgressBar'

const LinearLoader = ({ timing, resetTrigger  }) => {

    const [ progress, setProgress ] = useState(0)

    useEffect(() => {
      setProgress(0)  
      const startTime = Date.now()  
      const interval = setInterval(() => {
        const elapsedTime = Date.now() - startTime
        const newProgress = Math.min((elapsedTime / timing) * 100, 100)  
        setProgress(newProgress)  
        if (newProgress === 100) {
          clearInterval(interval)
        }
      }, 10)    
      return () => clearInterval(interval)      
    }, [resetTrigger, timing])

    return <LinearProgressBar progress={progress} timing={100} />


}

export default LinearLoader