import { Fragment, useEffect } from 'react'
import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux'
import { Link, NavLink } from 'react-router-dom'
import { useState } from 'react'
import { logout } from '../../../actions/userActions'
import { useSpring, animated } from 'react-spring'
import { getCategories } from '../../../actions/categoryActions'
import { getNavProducts } from '../../../actions/productActions'
import SpeedIcon from '../icons/navigation/SpeedIcon'
import PersonOutlineIcon from '../icons/people/PersonOutlineIcon'
import LogoutIcon from '../icons/login/LogoutIcon'
import LoginIcon from '../icons/login/LoginIcon'
import ToolTip from '../tools/ToolTip'
import Modal from '../../modals/modal/Modal'
import Contact from '../../modals/Contact'
import MailOutlineIcon from '../icons/navigation/MailOutlineIcon'
import MenuIcon from '../icons/navigation/MenuIcon'
import MenuOpenIcon from '../icons/navigation/MenuOpenIcon'
import Backdrop from '../tools/Backdrop'
import Search from './Search'
import Palette from '../colorPicker/Palette'
import Profile from '../../modals/Profile'
import BuyNow from '../tools/BuyNow'
import './header.css'
import ShoppingBasketIcon from '../icons/navigation/ShoppingBasketIcon'
import AddPhotoIcon from '../icons/actions/AddPhotoIcon'

const Header = () => {  

    const alert    = useAlert()
    const dispatch = useDispatch()

    const [ isMenuVisible,    setMenuVisible      ] = useState(false)
    const [ isNavVisible,     setIsNavVisible     ] = useState(false)
    const [ isModalVisible,   setIsModalVisible   ] = useState(false)    
    const [ isProfileVisible, setIsProfileVisible ] = useState(false)  

    const { user        } = useSelector( state => state.auth ) 
    const { categories  } = useSelector( state => state.categories ) 
    const { navProducts } = useSelector( state => state.navProducts )
    const { cartItems   } = useSelector( state => state.cart )

    useEffect(() => {
        dispatch(getCategories())
        dispatch(getNavProducts())   
    }, [dispatch])
    
    const logoutHandler = () => {
        toggleMenu()
        dispatch(logout())
        alert.success('Logged out')
    } 

    const toggleMenu = () => {
        setMenuVisible(isMenuVisible => !isMenuVisible)
    }

    const toggleModal = () => {
        setIsModalVisible(!isModalVisible)
    } 

    const toggleNav = () => {
        setIsNavVisible(!isNavVisible)
    }

    const menuAppear = useSpring({
        transform: isMenuVisible ? "translateY(0)" : "translateY(-40px)",
        opacity: isMenuVisible ? 1 : 0
    })  
    
    const navAppear = useSpring({
        transform: isNavVisible ? "translateY(0)" : "translateY(-40px)",
        opacity: isNavVisible ? 1 : 0
    })  

    const handleProfile = () => {
        setMenuVisible(false)
        setIsProfileVisible(!isProfileVisible)
    }

    return (
        <>    
        <header>
                
            <nav className="background-panel">

                <div>

                    <button className="icon-button">
                        <Link to="/" className="logo">
                            <img                    
                                src={`/images/logo.png`}
                                alt={`${process.env.REACT_APP_SITE_NAME} logo`}
                                width="40"
                                height="40" 
                                loading="lazy"                                        
                            />                
                        </Link>                    
                    </button>                       

                </div>  

                <div>

                    <Search />                   

                    <ToolTip title="Menu">
                        <button 
                            className="icon-button" 
                            aria-label="Menu"
                            onClick={toggleNav}
                        >
                            {isNavVisible ? <MenuOpenIcon /> : <MenuIcon /> }                                   
                        </button>  
                    </ToolTip>  

                    <ToolTip title="Cart">    
                                                                        
                        <small className="icon-tag">
                            <b>{cartItems.length}</b>
                        </small>                        

                        <BuyNow />

                    </ToolTip>

                    <ToolTip title="Contact">
                        <button 
                            className="icon-button"
                            aria-label="Contact" 
                            onClick={toggleModal}
                        >                                    
                                <MailOutlineIcon />                                    
                        </button>  
                    </ToolTip>  

                    {user ? (   
                        <>
                        <div className="relative">  
                            <ToolTip 
                                title={`${user && user.name} (${user && user.role})`} 
                                className="tooltip-right"
                                >
                                <button 
                                    className="icon-button" 
                                    aria-label="Avatar"
                                    onClick={() => toggleMenu()}
                                >
                                    <img 
                                        alt={user && user.name} 
                                        src={user.avatar ? user.avatar.url : '/images/default-avatar.jpg'}   
                                        style={{ width: '48px', height: '48px', borderRadius: '50%' }}                                    
                                    /> 
                                </button>
                            </ToolTip>                            
                        </div>                       
                    </>
                    ) : (
                        <ToolTip title="Login" className="tooltip-right">
                            <Link to="/login" aria-label="Navigate to login">
                                <button className="icon-button" aria-label="Login">
                                    <LoginIcon />
                                </button>
                            </Link>
                        </ToolTip>    
                    )}
                </div>   
                
            </nav>   

            {isMenuVisible && ( 
                <>
                <Backdrop
                    invisible
                    onClick={toggleMenu}
                /> 

                <animated.div className="dropdown-menu" style={menuAppear}>

                    {user && user.role === 'admin' && (
                        <>
                        <Link to="/admin/dashboard" onClick={toggleMenu}>
                            Dashboard &nbsp;
                            <button className="icon-button" aria-label="Dashboard">
                                <SpeedIcon />
                            </button>
                        </Link>

                        <Link to="/lightbox" onClick={toggleMenu}>
                            Lightbox &nbsp;
                            <button className="icon-button" aria-label="Lightbox">
                                <AddPhotoIcon />
                            </button>
                        </Link>

                        <Link to="/slideshow" onClick={toggleMenu}>
                            Slideshow &nbsp;
                            <button className="icon-button" aria-label="Slideshow">
                                <AddPhotoIcon />
                            </button>
                        </Link>

                        <Link to="/zoom-slideshow" onClick={toggleMenu}>
                            Zoom &nbsp;
                            <button className="icon-button" aria-label="Zoom slideshow">
                                <AddPhotoIcon />
                            </button>
                        </Link>
                        </>
                    )}

                    <span onClick={handleProfile}>
                        Profile &nbsp; 
                        <button className="icon-button" aria-label="Profile">
                            <PersonOutlineIcon />
                        </button>
                    </span>

                    <Link to="/orders/me" onClick={toggleMenu}>
                        Orders &nbsp; 
                        <button className="icon-button" aria-label="Orders">
                            <ShoppingBasketIcon />
                        </button>
                    </Link>
                    
                    <Link to="/" onClick={logoutHandler}>
                        Logout &nbsp; 
                        <button className="icon-button" aria-label="Logout">
                            <LogoutIcon />
                        </button>
                    </Link>

                </animated.div>
                </>
            )}

            {isNavVisible && ( 
                <>
                <Backdrop
                    invisible
                    onClick={toggleNav}
                /> 

                <animated.div 
                    className="dropdown-menu nav-dropdown" 
                    style={navAppear}
                >

                    <div className="wrapper">

                        <div className="parent">

                            {categories && categories.map((category, index) => ( 

                                <div key={category._id}>

                                    <h6>{category.name}</h6>

                                    <div className="d-flex gap-2">

                                        {category.image && (
                                            <figure>
                                                <img 
                                                    className="round"
                                                    src={category.image.url} 
                                                    alt={category.name} 
                                                    style={{ width: 75 }}
                                                />
                                            </figure>
                                        )}
                                        
                                        <ul>
                                            {navProducts && navProducts.map((product) => (
                                                <Fragment key={product._id}>
                                                    {product.category === category.name && (
                                                        <li onClick={() => setIsNavVisible(false)}>
                                                            <NavLink 
                                                                to={`/product/${product.slug}`} 
                                                                className={(navData) => (navData.isActive ? "active" : 'none')}
                                                            >
                                                                {product.name}
                                                            </NavLink>
                                                        </li> 
                                                    )}                                                
                                                </Fragment>  
                                            ))}                                                           
                                        </ul>
                                    </div>
                                </div>
                            ))}                            

                        </div>

                    </div>

                </animated.div>
                </>
            )}

            <Palette />
       
        </header>          

        <Modal
            isModalVisible={isModalVisible} 
            onBackdropClick={toggleModal}   
            content={ <Contact /> }
        />

        <Modal
            isModalVisible={isProfileVisible} 
            onBackdropClick={handleProfile}   
            content={ <Profile onClick={handleProfile} /> }
        />        
        </>
    )

}

export default Header