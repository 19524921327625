const Backdrop = ({ onClick, invisible }) => {

    return (

        <div 
            className="backdrop" 
            onClick={onClick} 
            style={{
                background: invisible ? 'none' : 'rgba(0,0,0,0.3)',
                width: '100%',
                height: '100%',
                position: 'fixed',
                top: 0,
                left: 0,
                zIndex: 2
            }}
        />

    )

}

export default Backdrop