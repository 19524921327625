import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSearchResults } from '../../../actions/productActions'
import { useMediaQuery } from 'react-responsive'
import ClearIcon from '../icons/actions/ClearIcon'
import SearchIcon from '../icons/navigation/SearchIcon'
import Product from '../../products/Product'
import Backdrop from '../tools/Backdrop'
import Loader from '../tools/Loader'
import Pagination from 'react-js-pagination'
import KeyboardArrowLeftIcon from '../icons/arrows/KeyboardArrowLeftIcon'
import KeyboardArrowRightIcon from '../icons/arrows/KeyboardArrowRightIcon'
import FirstPageIcon from '../icons/arrows/FirstPageIcon'
import LastPageIcon from '../icons/arrows/LastPageIcon'

const Search = () => {

    const dispatch = useDispatch()   
    const isMobile = useMediaQuery({ query: '(max-width: 501px)' }) 

    const [ keyword,     setKeyword     ] = useState('')
    const [ searchOpen,  setSearchOpen  ] = useState(false)
    const [ currentPage, setCurrentPage ] = useState(1) 

    const { loading, searchResults, resPerPage, searchCount } = useSelector( state => state.searchResults )

    const onChange = (e) => {  
        setKeyword(e.target.value)  
        if(e.target.value.trim().length > 3) {              
            setSearchOpen(true)            
        } 
    }

    useEffect(() => {
        dispatch(getSearchResults(currentPage, keyword)) 
    }, [dispatch, currentPage, keyword])

    const handleClose = () => {
        setSearchOpen(false)  
        setKeyword('') 
        setCurrentPage(1)
    }

    const setCurrentPageNo = (pageNumber) => {
        setCurrentPage(pageNumber)
    }

    return (
        <>        
        <div className="search">

            <input 
                className="large-screen"
                value={keyword} 
                placeholder="Search ..." 
                onChange={onChange} 
            />

            {isMobile ? (
                <button 
                    className="icon-button"
                    aria-label="Search" 
                    onClick={() => setSearchOpen(!searchOpen)}
                >
                    <SearchIcon />
                </button>  
            ) : (
                <span><SearchIcon /></span>  
            )}            

        </div> 

        {searchOpen && (
            <>
            <Backdrop
                invisible
                onClick={handleClose}
            /> 

            <div className="search-results text-center">  

                <button 
                    className="icon-button" 
                    aria-label="Close"
                    onClick={handleClose}
                    style={{ position: 'absolute', top: 10, right: 10 }}
                >

                    <ClearIcon />

                </button>

                <input 
                    className="mobile basic-input"
                    value={keyword} 
                    placeholder="Search ..." 
                    onChange={onChange} 
                    style={{ borderRadius: 24, margin: '0 auto 40px auto', width: '100%' }}
                />

                <div style={{ height: 30, marginBottom: 20 }}>

                {(resPerPage < searchCount) && keyword.length > 3 && (
                    <Pagination
                        activePage={currentPage}
                        itemsCountPerPage={resPerPage}
                        totalItemsCount={searchCount}                        
                        onChange={setCurrentPageNo}   
                        nextPageText={<KeyboardArrowRightIcon />}  
                        prevPageText={<KeyboardArrowLeftIcon />} 
                        firstPageText={<FirstPageIcon />} 
                        lastPageText={<LastPageIcon />}  
                    />
                )}  

                </div>

                {keyword.length > 3 && (                
                    <small>{searchCount} results found for "<i style={{ fontSize: '1.25rem' }}>{keyword}</i>"</small>
                )}

                <div style={{ overflowY: 'auto', height: 'calc(100vh - 180px)' }}>
                
                <div className="showroom">

                    {loading ? <Loader position="relative" /> : (
                        <>
                        {searchResults && searchResults.length > 0 && keyword.length > 3 &&
                            searchResults.map(product => (
                                <Product key={product._id} product={product} onClick={handleClose} />                                    
                            ))  
                        }  
                        </>
                    )}                    

                </div>

               

                </div>

            </div>
            </>
        )}
        </>
    )

}

export default Search