import './linearProgressBar.css'

const LinearProgressBar = ({ progress, timing }) => {

    return (

        <div className="linear-progress-container">
            <div
                className="linear-progress-bar"
                style={{ width: `${progress}%`, transitionDuration: `${timing}ms` }}
            />
        </div>

    )

}

export default LinearProgressBar