import { useState } from 'react'
import ModeToggle from './ModeToggle'
import ColorPicker from './ColorPicker'
import SettingsIcon from '../icons/navigation/SettingsIcon'
import ClearIcon from '../icons/actions/ClearIcon'
import Backdrop from '../tools/Backdrop'
import ToolTip from '../tools/ToolTip'

const Palette = () => {

    const [ isPaletteVisible, setIsPaletteVisible ] = useState(false)

    return (
        <div className="palette-container">

            {isPaletteVisible && (
                <Backdrop
                    invisible
                    onClick={() => setIsPaletteVisible(false)}
                /> 
            )}        

            <div className="palette">

                <div className={isPaletteVisible ? 'open' : ''}>
                    <div>
                        <h6>Mode</h6>
                        <br />
                        <ModeToggle />  

                        <hr />

                        <h6>Color</h6>
                        <br />
                        <ColorPicker />
                    </div>
                    
                    <span 
                        className={!isPaletteVisible ? 'open' : ''}
                        style={{ position: 'absolute', top: '5px', right: '5px' }}
                    >
                            <button 
                                className="icon-button" 
                                aria-label="Settings"
                                onClick={() => setIsPaletteVisible(false)}
                            >
                            <ClearIcon />
                        </button>
                    </span>
                </div>

                <div 
                    className={!isPaletteVisible ? 'open' : ''}
                    style={{ padding: '5px' }}
                >
                    <ToolTip 
                        title="Change theme"
                        className="tooltip-bottom-left"
                    >
                        <button 
                            className="icon-button" 
                            aria-label="Settings"
                            onClick={() => setIsPaletteVisible(true)}
                        >
                            
                                <SettingsIcon />
                            
                        </button>

                    </ToolTip>
                </div>

            </div> 

        </div>
    )

}

export default Palette