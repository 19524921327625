import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../actions/userActions'
import { useAlert } from 'react-alert'
import { formatMyDate } from '../../utils'
import Loader from '../layout/tools/Loader'
import EditOutlinedIcon from '../layout/icons/actions/EditOutlinedIcon'
import LogoutIcon from '../layout/icons/login/LogoutIcon'
import HomeIcon from '../layout/icons/navigation/HomeIcon'
import VisibilityIcon from '../layout/icons/visibility/VisibilityIcon'

const Profile = ({ onClick }) => {

    const alert    = useAlert()
    const dispatch = useDispatch()

    const { user, loading } = useSelector( state => state.auth )   

    const logoutHandler = () => {
        onClick()
        dispatch(logout())
        alert.success('Logged Out')
    }
    
    return (
        <>
        {loading || !user ? <Loader /> : (              

            <div className="wrapper">

                <h1>Profile</h1>    

                <div className="parent gap-4">

                    <div className="d-flex align-items-center justify-content-center">
                        <img 
                            src={user.avatar ? user.avatar.url : '/images/default-avatar.jpg'} 
                            alt={user.name} 
                            style={{ width: '159px', height: '159px', borderRadius: '50%' }}
                        />
                    </div>

                    <div 
                        className="d-flex justify-content-between direction-column"
                        style={{ flex: 1, textAlign: 'right' }}
                    >                         
                        <h6 style={{ color: '#999' }}>Name</h6>
                        <p>{user.name} ({user.role})</p>
                        <h6 style={{ color: '#999' }}>Email</h6>
                        <p>{user.email}</p>
                        <h6 style={{ color: '#999' }}>Joined</h6>
                        <p>{formatMyDate(user.createdAt)}</p>  

                    </div>
                </div>

                <div className="parent gap-2" style={{ marginTop: 40, alignItems: 'flex-start' }}>
                    
                    <div>    

                    <Link 
                            to="/" 
                            className="d-flex align-items-center"
                            onClick={onClick}
                        >
                            <HomeIcon /> &nbsp; Home
                        </Link> 

                        <br />                             

                        <Link 
                            to="/orders/me" 
                            className="d-flex align-items-center"
                            onClick={onClick}
                        >
                            <VisibilityIcon />&nbsp; My Orders
                        </Link>   

                    </div>                                 

                    <div style={{ textAlign: 'right' }}>                         

                        <Link 
                            to="/me/update" 
                            className="d-flex align-items-center justify-content-end"
                            onClick={onClick}
                        >
                            Update Profile  &nbsp; <EditOutlinedIcon />
                        </Link>                               

                        <br />

                        <Link 
                            to="/password/update" 
                            className="d-flex align-items-center justify-content-end"
                            onClick={onClick}
                        >
                            Update Password  &nbsp; <EditOutlinedIcon /> 
                        </Link>

                        <br />

                        <Link 
                            className="d-flex align-items-center justify-content-end"
                            onClick={logoutHandler}
                        >
                            Logout &nbsp; <LogoutIcon />
                        </Link> 

                    </div> 

                </div>    

            </div>                    
               
        )}            
        </>
    )

}

export default Profile
