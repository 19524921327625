import { useEffect, useRef, useState                       } from 'react'
import { useMediaQuery                                     } from 'react-responsive'
import { TransformComponent, TransformWrapper              } from 'react-zoom-pan-pinch'
import { LazyLoadImage                                     } from 'react-lazy-load-image-component'
import { cloudinaryURL, getSlideClass, handleImageLoad, handleTouchEndX, handleTouchStartX } from '../../../utils'
import KeyboardArrowLeftIcon                                 from '../../layout/icons/arrows/KeyboardArrowLeftIcon'
import KeyboardArrowRightIcon                                from '../../layout/icons/arrows/KeyboardArrowRightIcon'
import ZoomInMapIcon                                         from '../../layout/icons/zoom/ZoomInMapIcon'
import ZoomInIcon                                            from '../../layout/icons/zoom/ZoomInIcon'
import ZoomOutIcon                                           from '../../layout/icons/zoom/ZoomOutIcon'
import PlayArrowIcon                                         from '../../layout/icons/play/PlayArrowIcon'
import PauseIcon                                             from '../../layout/icons/play/PauseIcon'
import StopIcon                                              from '../../layout/icons/play/StopIcon'
import ClearIcon                                             from '../../layout/icons/actions/ClearIcon'
import DockAtBottomIcon                                      from '../../layout/icons/layout/DockAtBottomIcon'
import DockOnRightIcon                                       from '../../layout/icons/layout/DockOnRightIcon'
import CircularProgress                                      from '../../layout/icons/circularProgress/CircularProgress'
import LinearProgress                                        from '../../layout/icons/LinearProgress'
import LinearLoader                                          from '../../layout/icons/LinearLoader'
import Thumbnails                                            from './Thumbnails'
import 'react-lazy-load-image-component/src/effects/blur.css'
import './lightbox.css'

const Lightbox = ({ images, altTag, currentIndex, setCurrentIndex, setIsLightboxOpen, timing=8000 }) => {

    const prevButtonRef = useRef(null)
    const nextButtonRef = useRef(null)
    const hideTimerRef  = useRef(null)
    const sliderRef     = useRef(null)  
    const slideRefs     = useRef([])  
    const startXRef     = useRef(0)

    const isLandscape = useMediaQuery({ query: '(orientation: landscape)' })

    const [ playState,        setPlayState        ] = useState('stop')      
    const [ isDockedRight,    setIsDockedRight    ] = useState(isLandscape)  
    const [ zoomScale,        setZoomScale        ] = useState(1)
    const [ loadingStates,    setLoadingStates    ] = useState(Array(images.length).fill(true))        
    const [ shouldMatchSlide, setShouldMatchSlide ] = useState(true)  
    const [ showControls,     setShowControls     ] = useState(false)    

    const progress = ((currentIndex + 1) / images.length) * 100

    useEffect(() => {   
        document.body.style.overflow = 'hidden'
        const handleTabChange = () => {
            if (document.hidden) setPlayState('stop')
        }    
        document.addEventListener('visibilitychange', handleTabChange)    
        return () => {  
            document.removeEventListener('visibilitychange', handleTabChange)
            if (hideTimerRef.current) clearTimeout(hideTimerRef.current)
        }
    }, [])

    useEffect(() => {  
        if (playState !== 'playing') return
        const button = nextButtonRef.current
        const intervalId = setInterval(() => button.click(), timing)
        return () => clearInterval(intervalId)
    }, [playState, timing]) 

    useEffect(() => {
        slideRefs.current = slideRefs.current.slice(0, images.length)
    }, [images])

    const handleStart = (e) => { 
        handleMouseMove() 
        slideRefs.current[currentIndex].classList.add('dragging')
        if (zoomScale === 1) handleTouchStartX(e, startXRef)
            setPlayState(playState === 'stop' ? 'stop' : 'paused')
    }    
    const handleEnd = (e) => {
        if (zoomScale === 1) handleTouchEndX(e, startXRef, nextButtonRef.current, prevButtonRef.current)
    }
    
    const handleThumb = (i) => { 
        setCurrentIndex(i)  
        setShouldMatchSlide(true)
    } 

    const handleReset = (i) => {   
        const target = sliderRef.current.querySelectorAll('.lightbox-reset')[i]
        target.click()
    }
    
    const handleTransform = (e) => {
        const scale = e.instance.transformState.scale
        const xPosition = e.instance.transformState.positionX
        setZoomScale(scale || 1)    
        if (xPosition !== 0 && playState === 'playing' && scale === 1) {
            setPlayState('paused')
        }    
    } 

    const handleRotation = (dir) => {  
        setShouldMatchSlide(true)    
        handleReset(currentIndex)   
        setCurrentIndex(dir === 'right' 
            ? (currentIndex + 1) % images.length
            : (currentIndex - 1  + images.length) % images.length)             
    }

    const handlePlayState = () => {  
        handleReset(currentIndex)  
        if (playState === 'stop') {
            setPlayState('playing')             
        } else if (playState === 'paused') {
            setPlayState('playing') 
            handleRotation('right')
        } else {
            setPlayState('paused') 
        }       
    } 

    const handleStop = () => {
        setPlayState('stop')
        handleReset(currentIndex)
    } 

    const handleIsDocked = () => {
        setIsDockedRight(!isDockedRight)
        setShouldMatchSlide(true)
    }

    const handleClose = () => {
        setIsLightboxOpen(false)
        document.body.style.overflow = 'auto'                                            
        if (document.fullscreenElement) document.exitFullscreen() 
    }

    const handleMouseMove = () => {
        setShowControls(true)
        if (hideTimerRef.current) clearTimeout(hideTimerRef.current)  
        hideTimerRef.current = setTimeout(() => {
            setShowControls(false)
        }, 3000)
    }

    return (
       
        <div 
            className={`
                lightbox-container 
                ${isDockedRight ? 'lightbox-portrait' : 'lightbox-landscape'}
                ${playState === 'playing' 
                    ? 'lightbox-playing' 
                    : playState === 'paused'
                        ? 'lightbox-paused'
                        : ''
                }`
            }
        >  

            <div 
                ref={sliderRef}
                className="lightbox-stage"                 
                onMouseDown={handleStart}
                onMouseUp={handleEnd}
                onTouchStart={handleStart}
                onTouchEnd={handleEnd}
                onMouseMove={handleMouseMove}
            >  

                {images.map((image, index) => (

                    <TransformWrapper 
                        key={index}                        
                        panning={{ lockAxisY: zoomScale === 1 }} 
                        initialScale={1}
                        onTransformed={(e) => handleTransform(e)}  
                        onZoomStart={() => setPlayState(playState === 'playing' ? 'paused' : playState === 'paused' ? 'paused' : 'stop')}                 
                    >                  

                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                            <>
                            <div className={`lightbox-header ${index === currentIndex ? 'active' : ''}`}>
                                
                                <div className="lightbox-title">
                                    <small>{currentIndex + 1} / {images.length}</small>
                                    {(image.title || altTag) && <i />}
                                    <small>{image.title || altTag}</small>
                                    <small>{zoomScale > 1 && `x${zoomScale.toFixed(1)}`}</small>                                    
                                </div>

                                <div className="lightbox-icons">  

                                    <button 
                                        aria-label="zoom in"
                                        onClick={() => zoomIn()}
                                    >
                                        <ZoomInIcon />
                                    </button>

                                    <button 
                                        aria-label="zoom out"
                                        onClick={() => zoomOut()}
                                    >
                                        <ZoomOutIcon />
                                    </button>

                                    <button 
                                        className="lightbox-reset"
                                        aria-label="reset zoom"
                                        onClick={() => resetTransform()}
                                    >
                                        <ZoomInMapIcon />
                                    </button>                                    

                                    {images.length > 1 && (
                                        <button 
                                            aria-label={isDockedRight ? "landscape" : "portrait"}
                                            onClick={handleIsDocked}
                                        >
                                            {isDockedRight ? <DockAtBottomIcon /> : <DockOnRightIcon />}
                                        </button>
                                    )}                                     
                                    
                                    <button 
                                        aria-label="close"
                                        onClick={handleClose} 
                                    >
                                        <ClearIcon />
                                    </button>  

                                </div>

                            </div> 
                                                            

                            <div 
                                ref={(el) => (slideRefs.current[index] = el)}
                                className={`lightbox-slide ${getSlideClass(index, currentIndex, images.length)}`}
                            >  

                                <TransformComponent wrapperStyle={{ overflow: 'visible' }}> 
                                    
                                    {loadingStates[index] && <CircularProgress />} 

                                    <LazyLoadImage
                                        src={cloudinaryURL(image.url)} 
                                        alt={image.title || altTag}  
                                        effect="blur"
                                        placeholderSrc={cloudinaryURL(image.thumb_url || image.url)}
                                        onLoad={() => handleImageLoad(index, setLoadingStates)}
                                    />   

                                </TransformComponent>                                    

                            </div> 

                            {playState === 'stop' && (
                                <small className={`lightbox-footer ${index === currentIndex ? 'active' : ''}`}>
                                    {image.description}
                                </small> 
                            )}                                
                            </> 
                        )} 

                    </TransformWrapper>  

                ))}  

                {images.length > 1 && (
                    <>
                    {playState !== 'stop' && (
                        <button 
                            className="lightbox-stop"
                            aria-label="stop"
                            onClick={handleStop}
                        >
                            <StopIcon style={{ color: 'red' }} />
                        </button>
                    )}  

                    <div className={`lightbox-controls-container ${showControls ? 'visible' : ''}`}>

                        <LinearProgress progress={progress} timing={1000} />

                        <div className="lightbox-controls">
                            
                            <button 
                                ref={prevButtonRef}
                                aria-label="back"
                                onClick={(e) => handleRotation('left')}
                            >
                                <KeyboardArrowLeftIcon />
                            </button>

                            <button
                                className="lightbox-play"
                                aria-label={playState === 'playing' ? 'pause' : 'play'}
                                onClick={handlePlayState} 
                            >
                                {playState === 'playing' ? <PauseIcon /> : <PlayArrowIcon />}
                            </button>                            
                            
                            <button
                                ref={nextButtonRef}
                                aria-label="next"
                                onClick={() => handleRotation('right')}
                            >
                                <KeyboardArrowRightIcon />
                            </button> 

                        </div>

                    </div>                    
                
                    {playState === 'playing' && 
                        <div style={{ top: 0, left: 0, position: 'absolute', width: '100%' }}>
                            <LinearLoader timing={timing} resetTrigger={currentIndex} />
                        </div>
                    }  
                    </>
                )}

            </div> 

            {images.length > 1 && playState === 'stop' && (
                <Thumbnails
                    images={images} 
                    currentIndex={currentIndex} 
                    handleThumb={handleThumb}
                    isDockedRight={isDockedRight}
                    shouldMatchSlide={shouldMatchSlide}
                    setShouldMatchSlide={setShouldMatchSlide}
                />                 
            )}            

        </div>     
       
    )

}

export default Lightbox